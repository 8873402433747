import Vue from 'vue';
import Vuetify from 'vuetify/lib';

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				background: "#F7F7F7",
				recipe_background:"#FFF",
				title_color: "#333",
				primary_btn: '#1976D2',
				primary_btn_text: '#FFF'
			},
			dark: {
				background: "#0E0F2D",
				recipe_background: "#0E0F2D",
				title_color: "#F6921E",
				primary_btn: "#F6921E",
				primary_btn_text: '#FFF'
			}
		}
	}
});