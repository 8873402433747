<template>
  <v-app>
    <SideMenu/>
    <Sidebar/>
    <v-app-bar
      app
      elevate-on-scroll
      :style="{background: theme.background}"
      permanent
    >
      
      <!--<v-btn color="white" to="/" plain text x-large><v-img
          alt="BrukMat Logo"
          class="shrink"
          contain
          src="https://brukmat.no/logo.svg"
          height="25"
        />
      BrukMat</v-btn>-->
      <v-app-bar-nav-icon @click="$store.commit('toggleMenu')"></v-app-bar-nav-icon>
        <router-link to="/">
        <img
          alt="BrukMat Logo"
          src="https://brukmat.no/logo_v5_black_v2.svg"
          width="180"
          height="50"
          style="margin-top:7px"
        />
        </router-link>
      <!--<v-btn text class="mx-2" to="/om">
        Om
      </v-btn>
      <v-divider vertical>
      </v-divider>
      <v-btn text class="mx-2" to="/roadmap">
        Roadmap
      </v-btn>-->
      <v-spacer></v-spacer>


      <v-btn
        v-if="$store.getters.sidebarToggle === true"
        text
        @click="$store.commit('toggleSidebar', false)"
      >
        
        <v-icon large>mdi-arrow-collapse-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        text
        @click="$store.commit('toggleSidebar', true)"
      >
        <v-badge
          overlap
          color="green"
          :content="ingredientsSeletedAmount"
        >
        <v-icon large>mdi-food-apple</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <v-main :style="{background: theme.background}">
      <v-alert
      v-if="isOffline"
  color="red"
  colored-border
  border="left"
  elevation="2"
  class="ma-4"
  type="error"
>Tjenesten er for øyeblikket nede. Tjenesten er i fortløpende utvikling og vil derfor kunne være nede relativt hyppig. Sjekk tilbake senere. </v-alert>
      <!--<v-alert
  color="orange"
  colored-border
  border="left"
  elevation="2"
  class="ma-4"
  type="info"
>Vi jobber fortsatt med å legge inn oppskrifter, oppskrifter kan i mellomtiden ha feil eller manglende informasjon.</v-alert>-->
      <router-view :key="$route.fullPath" max="10"></router-view>
    </v-main>
    <Footer/>
    <v-bottom-navigation class="d-flex d-sm-none" fixed hide-on-scroll
      horizontal>
    <v-btn
        v-if="$store.getters.sidebarToggle === true"
        text
        @click="$store.commit('toggleSidebar', false)"
      >
        
        <v-icon large>mdi-arrow-collapse-right</v-icon>
      </v-btn>
      <v-btn
        v-else
        text
        @click="$store.commit('toggleSidebar', true)"
      >
        <v-badge
          overlap
          color="green"
          :content="ingredientsSeletedAmount"
        >
        <v-icon large>mdi-food-apple</v-icon>
        </v-badge>
      </v-btn>
  </v-bottom-navigation>
  </v-app>
</template>

<script>
import Sidebar from './components/Sidebar';
import SideMenu from './components/SideMenu';
import Footer from './components/Footer';
export default {
  name: 'App',
  components: {
    Sidebar,
    SideMenu,
    Footer
  },
  data:function(){
    return {
      drawer:false,
      showMenu:false,
      isOffline: false,
      value:true,
      showAPIFunctions:false
    }
  },
  computed: {
    ingredientsSeletedAmount (){
      console.log("Length",this.$store.getters.searchText.length);
      if(this.$store.getters.searchText.length == 0) return "0";
      return this.$store.getters.searchText.length
    },
    theme(){
      return this.$vuetify.theme.themes[(this.$vuetify.theme.dark) ? 'dark' : 'light'];
    }
  },
  mounted(){
    this.$axios.get("https://api.brukmat.no/heartbeat").then((data)=>{
      if(data.status === 0){
        this.isOffline = true;
        this.$store.commit('isOnline', false);
      } else {
        this.$store.commit('isOnline', true);
      }
    }).catch(()=>{
      this.isOffline = true;
      this.$store.commit('isOnline', false);
    })
  },
  methods:{
  }
};
</script>
