import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { store } from '../store/store'
import VueRouter from 'vue-router'
import router from '@/router'
import axios from 'axios'
import database from '@/classes/database.js'
import './registerServiceWorker'
import VueMeta from 'vue-meta'
Vue.prototype.$axios = axios;
Vue.prototype.$db = database;
Vue.prototype.$scheme = axios.get('https://api.brukmat.no/datascheme/nutrients').then((val)=>{
  console.log(val.data.data);
  return val.data.data;
});
function escapeURL(url){
  return url
  .replace(/%/g, 'prosent')
  // balsamicoeddik
  /*.replace(/ø/g, 'oe')
  // Nothing
  .replace(/æ/g, 'ae')
  // maarud
  .replace(/å/g, 'aa')
  .replace(/(\/)/g, '%2F')*/
  .replace(/\s/g, "-");
}
Vue.prototype.$encodeURL = url=>{
  let urlComponents = url.split(' - ');
  if(urlComponents.length === 1){
    // If only title, no keywords
    return escapeURL(urlComponents[0]);
  } else {
    console.log("GOT DOUBLETROUBLE");
    let keywords = urlComponents[1].split(", ").join("~");
    return escapeURL(urlComponents[0])+"/"+escapeURL(keywords);
  }
};
Vue.prototype.capitalizeEveryWord = words=>{
   var separateWord = words.toLowerCase().split(' ');
   for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1);
   }
   return separateWord.join(' ');
}
Vue.prototype.$joinKeywords = keywords=>{
  if(keywords == undefined || keywords.length == 0) {
    console.log("OHMYGID")
    return null;
  }
  return keywords.map(keyword=>escapeURL(keyword)).join("~");
}
Vue.prototype.$decodeURL = url=>{
  return url.replace(/-/g, " ") 
  // Only replaces first instance 
  //.replace(/(\/)/, " - ")
  .replace(/(prosent)/g, "%")
  .replace(/(~)/g, ", ");
};
Vue.use(VueRouter);
Vue.use(VueMeta);
let app = new Vue({
  vuetify,
  router,
  store,
  icons: {
    iconfont: 'mdiSvg',
  },
  render: h => h(App),
  data(){
    return {
      value:null,
      values:null
    }
  }
});
if (window.__INITIAL_STATE__) {
  // We initialize the store state with the data injected from the server
  //store.replaceState(window.__INITIAL_STATE__);
  
}
app.$mount('#app');