import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'
Vue.use(Vuex);
var localStorage = window.localStorage;
let apiKey = localStorage.getItem("api_key");
export const store = new Vuex.Store({
	state: {
		searchText:[],
		id_token:null,
		selectedIngredientsTitles:[],
		recipes:[],
		sidebarToggle:false,
		isOnline:false,
		sideMenuToggle:false,
		showAPIFunctions: apiKey ? true : false
	},
	mutations: {
		changeText(state, text){
			console.log("Search is changed");
			// Only apply changes
			if(text === false){
				text = [];
			}
			let changeMapped = text.map((item)=>{
				let exists = state.searchText.find((srchItem)=>{
					return srchItem.title == item.title;
				});
				if(exists === undefined){
					return item;
				} else {
					return {...exists, "existed":true};
				}
			});
			state.searchText = changeMapped;
		},
		changeMeasurement(state, obj){
			console.log("Measurement value called", obj);
			console.log("State",JSON.stringify(state.searchText[obj.index]));
			var oldObject = this.getters.searchText[obj.index].measurement;
			console.log("Old obj",oldObject)
			state.searchText[obj.index].measurement = {...oldObject, ...obj.value};
			state.searchText.push("");
			state.searchText.pop();
			console.log(state.searchText);
		},
		setSelectedIngredientTitles(state, array){
			state.selectedIngredientsTitles = array;
		},
		deleteIndexIngredients(state, index){
			console.log("DELETING INDEX:",index);

			state.searchText.splice(index, 1);
			state.selectedIngredientsTitles.splice(index, 1);
		},
		registerIdToken(state, value){
			state.id_token = value;
		},
		isOnline(state, boolean){
			state.isOnline = boolean;
		},
		toggleSidebar(state, boolean){
			state.sidebarToggle = boolean;
		},
		toggleMenu(state, boolean){
			if(boolean == undefined){
				console.log("Yo")
				state.sideMenuToggle = !state.sideMenuToggle;
			} else {
				console.log("Yu");
				state.sideMenuToggle = boolean;
			}
		},
		setRecipes: (state, value) => {
			state.recipes = value;
		},
		showAPIFunctions: (state, value) => {
			state.showAPIFunctions = value;
		},
		formatIngredient: (state, ingredient) => {
			let refArray = ingredient.nutrients_portion_ref.trim().split(" ");
			let valueArray = ingredient.nutrients_portion_value.trim().split(" ");
			let nutrient_portions_mapped = [];
			if(refArray[0] !== "") {
				nutrient_portions_mapped = refArray.map((refVal, refIndex) =>{
					return {
						text:refVal,
						value:[valueArray[refIndex], refVal]
					}
				});
			}
			return nutrient_portions_mapped;
		}
	},
	getters:{
		sidebarToggle: state => {
			return state.sidebarToggle;
		},
		idToken: state=>{
			return state.id_token;
		},
		getScheme: state=>{
			return state.scheme;
		},
		isOnline: state=>{
			return state.isOnline;
		},
		sideMenuToggle: state => {
			return state.sideMenuToggle;
		},
		showAPIFunctions: state=>{
			return state.showAPIFunctions;
		},
		nutrient_scheme: ()=>{
			return {
				water: {
					color:"cyan",
					title:"Vann"
				},
				protein: {
					color:"orange",
					title:"Protein"
				},
				fats_total: {
					color:"grey",
					title:"Fett"
				},
				energy_kcal: {
					color: "orange",
					title: "Kcal",
					multiplier: 9
				},
				fibre: {
					title:"Fiber",
					color:"amber lighten-1"
				},
				karbo: {
					title:"Karbo",
					color:"error"
				}
			}
		},
		selectedIngredientsTitles: state=> {
			return state.selectedIngredientsTitles;
		},
		searchText: state => {
			//let unflattened = state.searchText.map(ing => unflatten(ing));
			var searchText = unflatten(state.searchText);
			//console.log(state);
			//var customColored = state.scheme;
			var customColored = {
				water: {
					color:"cyan",
					title:"Vann"
				},
				protein: {
					color:"orange",
					title:"Protein"
				},
				fats_total: {
					color:"grey",
					title:"Fett"
				},
				energy_kcal: {
					color: "orange",
					title: "Kcal",
					multiplier: 9
				},
				fibre: {
					title:"Fiber",
					color:"amber lighten-1"
				},
				karbo: {
					title:"Karbo",
					color:"error"
				}
			}

			
			for(const ingIndex in searchText){
				let item = searchText[ingIndex];
				let nutrients = item.nutrients;
				let nutrient_array = [];
				for(const index in nutrients){
					let nutrient = nutrients[index];
					if(customColored[nutrient.title]){
						nutrient_array.push({
							color:customColored[nutrient.title].color || 'grey',
							title:customColored[nutrient.title].title,
							value:nutrient.value,
							multiplier:customColored[nutrient.title].multiplier || 1
						})
					}

				}
				searchText[ingIndex].nutrient_array = nutrient_array;

			}
			searchText = searchText.map(searchValues => {
				console.log(searchValues);
				let refArray = searchValues.nutrients_portion_ref.trim().split(" ");
				let valueArray = searchValues.nutrients_portion_value.trim().split(" ");
				let nutrient_portions_mapped = [];
				if(refArray[0] !== "") {
					nutrient_portions_mapped = refArray.map((refVal, refIndex) =>{
						return {
							text:refVal,
							value:[valueArray[refIndex], refVal]
						}
					});
				}
				var default_measurement;
				if(nutrient_portions_mapped.length > 0){
					console.log(nutrient_portions_mapped[0]);
					default_measurement = {
						multiplier:nutrient_portions_mapped[0].value[0],
						value:1,
						type:nutrient_portions_mapped[0].text
					}
				} else {
					default_measurement = {
						multiplier:1,
						value:100,
						type:"gram"
					}
				}
				// Default values
				console.log(valueArray, typeof valueArray, nutrient_portions_mapped);
				return {measurement:default_measurement, ...searchValues, portions:nutrient_portions_mapped};
			});
			/*let refArray = searchText.nutrients_portion_ref.split(" ");
			let valueArray = searchText.nutrients_portion_value.split(" ");
			let nutrient_portions_mapped = refArray.map((refVal, refIndex) =>{
				return {
					ref:refVal,
					value:valueArray[refIndex]
				}
			});
			console.log(nutrient_portions_mapped);*/
			console.log("Returned searchText", searchText);

			return searchText;
		},
		getRecipes: state => {
			return state.recipes;
		},
		getSelectedIngredients: state => {
			let unflattened = unflatten(state.searchText);
			let ingredients = unflattened.map((item)=>{
				let keywords = item.keywords.join(", ");
				if(!keywords) return {product:item.product, raw:item};
				return {product: item.product + " - "+keywords, raw:item};
			});
			return ingredients;
		}
		/*ingredient_nutrient_values: (state, value) =>{
			if (state.searchText == '' || state.searchText == false){
				return false;
			} else {
				let ingredient = value;
				let data = [
					ingredient.nutrients_energy_kcal,
					ingredient.nutrients_fats_total,
					ingredient.nutrients_protein,
					ingredient.nutrients_kolest,
					ingredient.nutrients_fibre
				]
				return data;
			}
		},
		ingredient_nutrient_labels: state =>{
			if (state.searchText == '' || state.searchText == false){
				return false;
			} else {
				let data = [
					"Kcal",
					'Fett',
					"Protein",
					"Kolestrol",
					"Fiber"
				]
				return data;
			}
		}*/
	}
})
function unflatten(data) {
	if(data.length == 0){
		return [];
	}
	//console.log("UNFLATTENING");
	var resultArray = [];
	//console.log("PREDATA", data);
	for(let ji in data){
		let result = {};
		let j = data[ji];
		for (var i in j) {
			var keys = i.split(/_(.+)/);
			result[i] = j[i];
			if(keys.length > 1){
				if(!result[keys[0]]) result[keys[0]] = [];

				result[keys[0]].push({"value":j[i], "title":keys[1]});
			}
		}
		if(result["nutrients"]){
			//console.log("SORTING...");
			result["nutrients"] = result["nutrients"].sort((a,b) => (a.title.localeCompare(b.title)));
		}
		//console.log("Result:",result);

		resultArray.push(result);
		//console.log("result:",resultArray)
		/*keys.reduce(function(r, e, j) {
		return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : [])
		}, result)*/
	}
  return resultArray;
}