<template>
	<v-navigation-drawer 
		app
		disable-resize-watcher
		v-model="drawer"
		>
	<v-list-item>
		<v-list-item-content>
			<v-list-item-title class="text-h6">
				Meny
			</v-list-item-title>
		</v-list-item-content>
	</v-list-item>
	<v-divider></v-divider>
	<v-list
		dense
		nav
	>
		<v-list-item
			v-for="item in items"
			:key="item.title"
			link
			:to="item.link"
		>
			<v-list-item-icon>
				<v-icon>{{ item.icon }}</v-icon>
			</v-list-item-icon>

			<v-list-item-content>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-list-item
			link
			href="https://brukmat.no/nyheter/"
		>
			<v-list-item-icon>
				<v-icon>mdi-newspaper-variant</v-icon>
			</v-list-item-icon>

			<v-list-item-content>
				<v-list-item-title>Nyheter/Blogg</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<div v-if="$store.getters.showAPIFunctions">
			<v-divider></v-divider>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title>Admin</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-for="item in adminItems"
			:key="item.title"
			link
			:to="item.link">
				<v-list-item-icon>
				<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</div>
		
	</v-list>
	</v-navigation-drawer>
</template>
<script>
	export default {
		data:function(){
			return {
				items: [
					{ title: 'Hjem', link:"/",   icon: 'mdi-view-dashboard' },
					{ title: 'Oppskrifter',   link:"/alle-oppskrifter", icon: 'mdi-book-open-variant' },
					{ title: 'Om',   link:"/om", icon: 'mdi-domain' },
					{ title: 'Roadmap',   link:"/roadmap", icon: 'mdi-map' },
				],
				adminItems: [
					{ title: 'Legg til oppskrift', link:"/legg-til-oppskrift", icon: 'mdi-plus-box'},
					{ title: 'Rediger ingrediens beskrivelse', link:"/rediger-ingrediens-beskrivelse", icon:'mdi-pencil-outline'}
				]
			}
		},
		computed: {
			drawer:{
				set(boolean){
					this.$store.commit('toggleMenu', boolean);
				},
				get() {
					return this.$store.getters.sideMenuToggle;
				}
			}
		}
	}
</script>