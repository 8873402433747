<template>
	<div>
	<v-dialog v-model="openConfig"
	max-width="600px">
		<v-card>
				<v-card-title class="headline">
					Instillinger
				</v-card-title>

				<v-card-text>
					<v-text-field
									label="API-nøkkel"
									v-model="api_key"
								></v-text-field>
						<v-switch
					v-model="theme"
					inset
					label="Mørkt fargetema"
				></v-switch>
				Login (ikke funksjonelt):
				<GoogleLogin :params="params" :onSuccess="onSuccess">Google</GoogleLogin>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="saveConfig()"
					>
						Lagre
					</v-btn>
					<v-btn
						color="primary"
						text
						@click="openConfig = false"
					>
						Lukk
					</v-btn>
				</v-card-actions>
			</v-card>
	</v-dialog>
	<v-footer padless>
		

		<v-card
				flat
				width="100%"
				class="text-center"
			>
				<v-card-text>
					<v-btn
				
				text
				rounded
				class="my-2"
				@click="openConfig = true"
			>
				Instillinger
			</v-btn>
				<v-btn to="/roadmap" text rounded class="my-2">
					RoadMap
				</v-btn>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-text class="white--text">
					{{ new Date().getFullYear() }} — <strong>BrukMat.no</strong>
				</v-card-text>
			</v-card>
		</v-footer>
	</div>
</template>
<script type="text/javascript">
	var localStorage = window.localStorage;
	import GoogleLogin from 'vue-google-login';
export default {
	components: {
		GoogleLogin
	},
	data:function(){
		return {
			openConfig:false,
			api_key:'',
			theme:false,
			// client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
			params: {
				client_id: "1047169051652-j9e01p77l045939ql20l67qci14cm943"
			},
			// only needed if you want to render the button with the google ui
			renderParams: {
				width: 250,
				height: 50,
				longtitle: true
			}
		}
	},
	created(){
		let themeColor = localStorage.getItem('theme_color');
		if(themeColor !== null){
			this.theme = themeColor == 'true' ? true : undefined;
		} else {
			let likesTheDark = window.matchMedia('(prefers-color-scheme: dark)');
			if(likesTheDark.matches === true) {
				this.theme = true;
				this.$vuetify.theme.dark = true;
			}
		}
		
	},
	watch:{
		theme(val){
			console.log("Setting theme to", val)
			this.$vuetify.theme.dark = val ? true : undefined;
		}
	},
	methods:{
		saveConfig(){
			console.log(this.theme)
			localStorage.setItem('theme_color', this.theme);
			if(this.api_key === ''){
				console.log("Removing API key");
				localStorage.removeItem('api_key');
				this.$store.commit('showAPIFunctions', false);
			} else {
				console.log("Earlier value:",localStorage.getItem('api_key'));
				localStorage.setItem('api_key', this.api_key);
				console.log("API_KEY", this.api_key);
				this.$store.commit('showAPIFunctions', true);
			}
			
			
		},
		onSuccess(googleUser) {
			console.log(googleUser);
			console.log(googleUser.Zb.id_token);

			// This only gets the user information: id, name, imageUrl and email
			console.log(googleUser.getBasicProfile());
			this.$store.commit('registerIdToken', googleUser.Zb.id_token);
		}
	}
}
</script>